import * as React from "react"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import Footer from "../../components/footer"
import { StaticImage } from "gatsby-plugin-image"


export default class News extends React.Component {

  render() {

    return (

      <Layout>

        <Seo title="SPCO" lang="en" />

        <section className="wow animate__fadeIn">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12 col-lg-5 col-md-6 text-center md-margin-30px-bottom wow animate__fadeInLeft">
                <StaticImage
                  src="../../images/IMG_4109.jpg"
                  formats={["auto", "webp", "avif"]}
                  alt=""
                  className="border-radius-6 w-100 shadow"
                />
              </div>
              <div className="col-12 col-lg-7 col-md-6 text-center text-md-start padding-eight-lr lg-padding-six-lr md-padding-15px-lr wow animate__fadeInRight" data-wow-delay="0.2s">

                <span className="text-deep-pink alt-font margin-10px-bottom d-inline-block text-medium">ADIPEC Exhibition, October 2023</span>

                <p><strong>Abu Dhabi, United Arab Emirates</strong></p>
                <p>ADIPEC 2023 saw the uniting of the energy industry through people, policy, technology, and capital, to ensure a just and efficient energy transition. This exhibition aims to gather all the leading companies to tackle the ways of doing business, expression of abilities, exploration of oil, and so on. </p>
                <p>Having taken part in ADIPEC 2023, the most influential energy event, our company took the next step towards innovation, decarbonizing, and consistent growth in the oil and gas industry. ADIPEC 2023 brought us great opportunities to express our capabilities and novel partnerships and engage with new clients worldwide. </p>
                <p>Our presence in this valuable event helps us to become well-known among others and figure out state-of the-art machines and equipment used in the refineries. </p>
                <p>Our team hopes to find new solutions against carbon emissions and apply cutting-edge technologies and practices for conserving the ecosystem.</p>
                <small>13 October 2023</small>
              </div>
            </div>
          </div>
        </section>

        <Footer />

      </Layout>

    )

  }

}